$content-width: 80%;

@import "minima";
@import "custom-bootstrap";


body {
  font-family: "Roboto", serif;
  font-weight: $base-font-weight;
  font-size: #{$base-font-size};
  line-height: #{$base-line-height};
  color: #333333;
  line-height: 1.5;
  background-color: white;
}

// a {
//   color: #169fe6 !important;
//   //16a34a !important;
// }

.home-paragraph {
    font-size: 18px;
}

.project-card-img {
  width: 100%;
  object-fit: contain;
  aspect-ratio: 1 / 1;
}

.logo-face {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-top: 20px;
  margin-bottom: 20px;
  text-align: center;
  display: block;
}

.site-nav {
  float: none;
  text-align: center;
  line-height: 0;
  margin-bottom: 20px;
  margin-top: 20px;
}

.site-header {
  border-top: 0.5rem solid #169fe6; // Default color for Home
  display: flex;
  justify-content: center;
  width: 100%;
}

.site-header-oceans {
  border-top-color: #169fe6;
}

.site-header-notes {
  border-top-color: #cbb35c;
}

.site-header-music {
  border-top-color: #000000;
}

.post-link {
  font-size: 1.5rem;
  color: #333333 !important;
  text-decoration: none;
}

.index-section-title {
  margin-bottom: 1.5rem;
}

.copyright {
  font-size: 14px;
  font-weight: bolder !important;
  color: #666666;
}

.post-title-list {
  margin-bottom: 0px;
}

.excerpt {
  font-size: 16px;
  color: #666666;
  text-align: justify;
}

.label {
  color: white;
  border-radius: 5px;
  padding: 4px;
  background-color: gold;
  font-size: 12px;
  margin-right: 3px;
}

@font-face {
  font-family: "roboto";
  src: url("../../assets/fonts/Roboto-Light.ttf");
}

@font-face {
  font-family: "roboto-bold";
  src: url("../../assets/fonts/Roboto-Bold.ttf");
}

@font-face {
  font-family: "roboto-italic";
  src: url("../../assets/fonts/Roboto-Italic.ttf");
}

strong {
  font-family: "roboto-bold";
}

em {
  font-family: "roboto-italic";
}

.result li.active a {
    color: lightgray;
    cursor: default;
}

hr {
  margin-bottom: 20px;
  margin-top: 30px;
}

.post-content h3 {
  margin-top: 20px;
  margin-bottom: 20px;
}
